import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import { useWeb3React } from "@web3-react/core";

import {useContract, useWeb3} from "../../Standard/hooks/useCommonContracts";
import {wei2eth} from "../../Standard/utils/common";
import Button from "../../Standard/components/Button";
import './index.css'
import VestingSender from "../../contracts/VestingSender.json"

const vestingSenderAddress = '0x1027C64cFC71ecC9c9aD2AD96D853b5A21AD894c'


const Vesting = () => {
    // @ts-ignore
    const {account, active} = useWeb3React()
    const {locale} = useContext(LocaleContext)
    const [allocation, setAllocation] = useState(0)
    const vestingSenderContract = useContract(VestingSender.abi, vestingSenderAddress)

    // if(!account || !active){
    //     return null
    // }

    useEffect(() => {
        if (account) {
            // @ts-ignore
            vestingSenderContract.methods.userInfo(account).call().then(res => {
                    setAllocation(res.availableToWithdraw)
                }
            )
        }
    }, [account])

    const vest = async () => {
        await vestingSenderContract.methods.withdraw().send({from: account});
    }

    return (
        <div className="Vesting">
            <h1 className={"text-5xl mb-4"}>Welcome to MMPRO Vesting</h1>
            <h2 className={'mb-3 text-2xl'}>{`Your allocation: ${wei2eth(allocation)}`}</h2>
            {/*@ts-ignore*/}
            <Button
                onClick={async () => {
                    await vest();
                }}
            >
                {"Perform Vesting"}
            </Button>
        </div>
    )
};

export default Vesting

