/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React from "react";

import StandardAppContainer from "./Standard/StandardAppContainer";
import Vesting from "./pages/Vesting";

export const App = () => {
  return (
      <StandardAppContainer forcedLocale={'en'}>
          <Vesting/>
      </StandardAppContainer>
  );
};
